import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { FaTimesCircle } from 'react-icons/fa';
import service from '../../public/service';
import { register } from '../../serviceWorker';

const frame = {
  "login" : ["email", "password"],
  "register" : ["email", ["region", "phone"] ,"first_name", "last_name", "password", "confirm_password"],
  "otp" : ["6_digit"]
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.state={
      type : "login",
      errors : {}
    }
    this.change = this.change.bind(this)
    this.register = this.register.bind(this)
    this.otpConfirm = this.otpConfirm.bind(this)
  }
  
  componentDidMount() {    
  }

  componentWillUnmount() {
  }

  change(key, text) {
    const {type} = this.state
    var { logindata, updatevalue, registerdata } = this.props
    if( type === "login" ) {
      logindata[key] = text
      updatevalue({
        logindata : logindata
      })
    } else if( type === "register" ) {
      registerdata[key] = text
      updatevalue({
        registerdata : registerdata
      })
    } else if( type === "otp" ) {
      updatevalue({
        otp : text
      })
    }
  }

  register() {
    const {register, registerdata} = this.props
    var errors = {}
    const items = ["email", "region", "phone" ,"first_name", "last_name", "password", "confirm_password"]
    var valid = true

    //CHECK EMPTY OR INVAILD BOXES
    for (var i of items) {
      if( !(i in registerdata) ) {
        errors[i] = true
        valid = false
      }
      if( valid === true ) {
        if( registerdata[i].length === 0 ) {
          valid = false
          errors[i] = true
        }
        if ( i === "email" ) {
          if( !service.validateEmail(registerdata[i]) ) {
            valid = false
            errors[i] = true
          }
        }
      }

    }

    //CHECK CONFIRM PASSWORD
    if( valid ) {
      if( registerdata["password"] !== registerdata["confirm_password"] ) {
        valid = false
        errors["confirm_password"] = true
      }
    }

    if( valid ) {
      if( registerdata["phone"].length <= 7 ) {
        valid = false
        errors["phone"] = true
      }
    }

    if( valid ) {
      var obj = JSON.parse( JSON.stringify(registerdata) )
      obj['phone'] = `${obj['region'].replace("+", "")}:${obj['phone']}`
      const cb = ()=>{
        this.setState({ type : "otp", errors : {}})
      }
      register( obj, cb )
    } else {
      this.setState({errors : errors})
    }
  }

  otpConfirm() {
    const {otp, i18n, language, updatevalue, registerdata, otpConfirm} = this.props
    var {logindata} = this.props
    
    if( service.validateNum(otp) && (otp.length === 6) ) {
      
      

      const cb = () => {
        this.setState({
          type : "login"
        })

        logindata['email'] = registerdata["email"]
        logindata['password'] = registerdata["password"]

        updatevalue({
          logindata : logindata,
          registerdata : {"region" : "+852"}
          //showLogin : false
        })
      }
      
      otpConfirm( otp, cb )

    } else {
      alert(i18n['invalid_passcode'][language])
    }
  }

  render() {
    const {showLogin, updatevalue, config, hasLogin, i18n, language, logout, logindata, registerdata, login, otp } = this.props
    const {regions} = config
    const { type, errors } = this.state
    const { register, otpConfirm} = this
    var obj = {}
    if( type === "login" ) {
      obj = logindata
    } else if( type === "register" ) {
      obj = registerdata
    } else {
      obj = { "6_digit" : otp } 
    }

    return (
      <div className={["Login", showLogin?"Show":"Hide"].join(" ")}>
        <div className={["Content"].join(" ")}>
          <FaTimesCircle onClick={()=>{
            updatevalue({
              showLogin : false
            })
          }} className={['Close-Btn'].join(" ")} />

          {
            hasLogin && 
            <>            
              <Link 
                onClick={()=>{
                  updatevalue({showLogin : false})
                }}
                to={"home"}
                className={['Btn'].join(" ")}>{ 
                i18n['home'][language] }</Link>
              <Link 
                onClick={()=>{
                  updatevalue({showLogin : false})
                }}
                to={"userprofile"}
                className={['Btn'].join(" ")}>{ 
                i18n['user'][language] }</Link>
              {
                config['wallet'] && config['topup'] &&
                <Link 
                  onClick={()=>{
                    updatevalue({showLogin : false})
                  }}
                  to={`/wallet`}
                  className={['Btn'].join(" ")}>{ 
                  i18n['topup_n_gift'][language].replace("\n", "") }</Link>
              }
              <div 
                onClick={ ()=>logout() }
                className={['Btn'].join(" ")}>{ 
                i18n['logout'][language] }</div>
            </>

          }

          {
            !hasLogin && frame[type].map( (v,k) => {
              if( typeof(v) === "string" ) {
                return(
                  <div className={['Line'].join(" ")} key={k}>
                    <div className={["Title", (v in errors)?"Warn":"" ].join(" ")}>
                      <span>{ i18n[v][language]  }</span>
                      <span> { (v in errors)?i18n['required'][language]:"" } </span>
                    </div>
                    <input 
                      autoComplete={"new-password"}
                      type={ (v.indexOf("password") > -1)?"password":"text" }
                      onChange={(e)=>{
                        var valid = true
                        if( v==="6_digit" ) {
                          valid = service.validateNum(e.target.value)
                        }
                        if(valid)
                          this.change( v, e.target.value )
                      }}
                      className={["Input"].join(" ")} 
                      value={ (v in obj)?obj[v]:"" } />
                  </div>
                )
              } else {
                return(
                  <div className={['Tel'].join(" ")} key={k}>
                    <div className={['Line'].join(" ")}>
                      <div className={["Title", ("region" in errors)?"Warn":"" ].join(" ")}>
                                <span>{ i18n[  "country" ][language] }</span>
                                <span> { ("region" in errors)?i18n['required'][language]:"" } </span>
                      </div>
                      <select className='Region' 
                        value={ obj['region']?obj['region']:"" }
                        onChange={(e)=>{
                          console.log(e.target.value)
                      }}>
                        {
                          regions.map((v2,k2)=>{
                            return(
                              <option value={v2['value']} key={k2}>{ v2['value'] }</option>
                            )
                          })
                        }
                      </select>
                    </div>
                    <div className={['Line'].join(" ")}>
                      <div className={["Title", (v[1] in errors)?"Warn":"" ].join(" ")}>
                              <span>{ i18n[  v[1] ][language] }</span>
                              <span> { (v[1] in errors)?i18n['required'][language]:"" } </span>
                      </div>
                      <input onChange={(e)=>{
                          if( service.validateNum(e.target.value)  ) {
                            this.change( v[1], e.target.value )
                          }      
                        }}
                        className={["Input"].join(" ")} 
                        value={ (v[1] in obj)?obj[v[1]]:"" } />
                    </div>
                  </div>
                )
              }
            } )
          }
          {
            !hasLogin && (type !== "otp") &&
            <div onClick={()=>{
                this.setState({
                  type : type==="login"?"register":"login"
                })
              }}
              className={["Link"].join(" ")}>{ 
                i18n[  type==="register"?"signin":'register_msg'][language]
              }</div>
          }
          {
            !hasLogin && 
            <div onClick={ ()=>{
                if( type === "login" ) {
                  login()
                } else if( type === "register" ) {
                  register()
                  //this.setState({ type : "otp"})
                } else if( type === "otp" ) {
                  otpConfirm()
                }
              } } className={['Btn', "L"].join(" ")}>
                  { 
                    ( type === "login" )?
                    i18n['signin'][language]:
                    (
                      type === "register"?
                        i18n['signup'][language]:
                        i18n['signup'][language]
                    )
                    
                  }
            </div>
          }
          

        </div>

        <div className={["Frame"].join(" ")} onClick={()=>{
          updatevalue({
            showLogin : false
          })
        }}></div>
      </div>
    )
  }
}

export default Login;


/*
[
            {
                "id" : 0,
                "title" : { 
                        "eng" : "Redemption Branch (With Gift Set)",
                        "zhtc" : "月餅禮券發售及禮盒換領分店",
                        "zhcn" : "月饼礼券发售及礼盒换领分店" 
                },
                "stores" : {
                    "kingsdelicacy" : ["CWB", "TST", "KT", "TKO" ],
                    "godofteppanyaki" : ["CWB"]    
                }
            },
            {
                "id" : 1,
                "title" : { 
                        "eng" : "Redemption Branch\n(No Gift Set)",
                        "zhtc" : "月餅禮券發售分店\n(不設禮盒換領)",
                        "zhcn" : "月饼礼券发售分店\n(不设礼盒换领)" 
                },
                "stores" : {
                    "godofteppanyaki" : ["WC", "TST", "KT"]    
                }
            }
        ]
        */